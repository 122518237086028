import request from "@/utils/request";

// PermissionGroup
export function permissionGroupList(params) {
  return request({ url: `/permission_groups/`, method: "get", params });
}

// Role
export function roleList(params) {
  return request({ url: `/roles/`, method: "get", params });
}

export function roleCreate(data) {
  return request({ url: `/roles/`, method: "post", data });
}

export function roleUpdate(data) {
  return request({ url: `/roles/${data.id}/`, method: "put", data });
}

export function roleDestroy(data) {
  return request({ url: `/roles/${data.id}/`, method: "delete", data });
}

// User
export function userList(params) {
  return request({ url: `/users/`, method: "get", params });
}

export function userCreate(data) {
  return request({ url: `/users/`, method: "post", data });
}

export function userUpdate(data) {
  return request({ url: `/users/${data.id}/`, method: "put", data });
}

export function userDestroy(data) {
  return request({ url: `/users/${data.id}/`, method: "delete", data });
}

export function userResetPassword(data) {
  return request({ url: `/users/${data.id}/reset_password/`, method: "post", data });
}

// GetToken
export function getToken(data) {
  return request({ url: `/user/get_token/`, method: "post", data }, false);
}

// RefreshToken
export function refreshToken(data) {
  return request({ url: `/user/refresh_token/`, method: "post", data });
}

// GetInfo
export function getInfo(params) {
  return request({ url: `/user/info/`, method: "get", params });
}

// SetPassword
export function setPassword(data) {
  return request({ url: `/user/set_password/`, method: "post", data });
}

// LabelConfig
export function labelConfigList(params) {
  return request({ url: `/label/config/`, method: "get", params });
}

export function labelConfigUpdate(data) {
  return request({ url: `/label/set_config/`, method: "post", data });
}